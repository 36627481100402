<template>
  <UserHeader />
  <div class="p-3">
    <div style="content-inner" v-if="isLoading">
      <div class="d-flex justify-content-center flex-column">
        <img src="@/assets/img/loading/loading_estrategia.gif" class="ms-wd" />
        <p class="text-primary text-center">CARREGANDO DADOS...</p>
      </div>
    </div>
    <div v-else>
      <Question :question="question" @getQuiz="getQuiz" />
    </div>
  </div>
</template>
<script>
import Question from '@/components/cards/Question.vue'
import UserHeader from '@/components/cards/UserHeader.vue'

export default {
  name: 'Quiz',
  data() {
    return {
      isLoading: true,
      question: null,
    }
  },
  components: {
    Question,
    UserHeader,
  },
  methods: {
    async getQuiz() {
      try {
        this.question = await this.$store.dispatch('getQuiz', {
          user: this.user,
          uuid: this.$route.params.uuid,
        })
        this.isLoading = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
  },
  mounted() {
    this.getQuiz()
  },
}
</script>
