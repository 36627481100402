<template>
  <div>
    <h6 class="text-warning">
      QUESTÃO {{ question.index }} DE {{ question.numberOfQuestions }}
    </h6>
    <h4>{{ question.index }}) {{ question.question }}</h4>
    <div>
      <div v-for="(option, index) in options" :key="option.optionId">
        <button
          class="card btn btn-link p-0 w-100"
          :disabled="disabledOptions"
          @click="selectOption(index)"
          :class="option.isSelected ? 'bg-purple' : 'bg-light'"
        >
          <div class="card-body w-100 p-1">
            <div class="row d-flex align-items-center">
              <div class="col-2">
                <div
                  class="d-inline-flex align-items-center justify-content-center bg-dark bg-opacity-50 text-white lh-1 rounded-pill w-40px h-40px me-3"
                >
                  <span class="letter-icon">{{
                    26 > options.length ? alphabet[index] : index + 1
                  }}</span>
                </div>
              </div>
              <div class="col-10">
                <div class="d-flex justify-content-start align-items-center">
                  <div class="fw-semibold text-muted">{{ option.answer }}</div>
                </div>
              </div>
            </div>
          </div>
        </button>
      </div>
      <div v-if="responseQuestion === null">
        <button
          :disabled="disabledButton"
          @click="sendAnswer"
          class="w-100 btn btn-purple btn-lg rounded-pill"
        >
          {{ textButton }}
        </button>
      </div>
      <div v-else>
        <div
          class="alert bg-opacity-50 text-center"
          :class="responseQuestion.isCorrect ? 'bg-purple' : 'bg-warning'"
          role="alert"
        >
          <h4 class="alert-heading">
            {{ responseQuestion.isCorrect ? 'Acertou!' : 'Errou!' }}
          </h4>
          <p>
            {{
              responseQuestion.isCorrect
                ? responseQuestion.messageCorrectOption
                : responseQuestion.messageErrorOption
            }}
          </p>
          <div v-if="question.index === question.numberOfQuestions">
            <router-link class="btn btn-purple rounded-pill" to="/profile"
              >VER PERFIL</router-link
            >
          </div>
          <div v-else>
            <button
              class="btn btn-purple rounded-pill w-100"
              @click="this.$emit('getQuiz')"
            >
              Próxima Questão
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateTimeSqlToDateTimeBR } from '@/utils/date'

export default {
  name: 'Question',
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      alphabet: [],
      options: [],
      dateTimeSqlToDateTimeBR,
      selectedOptionId: null,
      disabledButton: true,
      disabledOptions: false,
      textButton: 'ENVIAR RESPOSTA',
      responseQuestion: null,
    }
  },
  methods: {
    selectOption(index) {
      this.options.forEach((option) => {
        // eslint-disable-next-line no-param-reassign
        option.isSelected = false
      })
      this.selectedOptionId = this.options[index].optionId
      this.options[index].isSelected = true
      this.disabledButton = false
    },
    generateAlphabet() {
      // eslint-disable-next-line no-plusplus
      for (let i = 65; i <= 90; i++) {
        this.alphabet.push(String.fromCharCode(i))
      }
    },
    async sendAnswer() {
      try {
        this.disabledOptions = true
        this.disabledButton = true
        this.textButton = 'ENVIANDO RESPOSTA...'
        this.responseQuestion = await this.$store.dispatch('saveAnswer', {
          uuid: this.$route.params.uuid,
          cpf: this.$store.getters.user.cpf,
          id: this.question.id,
          questionId: this.question.questionId,
          optionId: this.selectedOptionId,
        })
        this.isLoading = false
      } catch (error) {
        this.disabledButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  watch: {
    selectedOptionId() {
      this.disabledButton = !this.selectedOptionId
    },
    question() {
      this.options = this.question.options
      this.selectedOptionId = null
      this.disabledButton = true
      this.disabledOptions = false
      this.textButton = 'ENVIAR RESPOSTA'
      this.responseQuestion = null
    },
  },
  mounted() {
    this.generateAlphabet()
    this.options = this.question.options
  },
}
</script>
