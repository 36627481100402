<template>
  <div class="home-container">
    <UserHeader />
    <div class="p-3">
      <div style="content-inner" v-if="isLoading">
        <loading-data />
      </div>
      <div v-else>
        <div class="row">
          <div v-if="quiz.length === 0" class="col-12 mt-5 text-center">
            <img src="@/assets/img/logos/logo_roxo.png" class="ms-wd" />
            <h4 class="mt-4 text-white">Tudo certo por aqui!</h4>
            <p class="text-white">
              Você já respondeu todos os questionários disponíveis no momento.
            </p>
          </div>
          <div
            class="col-12 col-sm-6 col-md-4 col-lg-3"
            v-for="q in quiz"
            :key="q.uuid"
          >
            <AvaliableQuiz :quiz="q" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AvaliableQuiz from '@/components/cards/AvaliableQuiz.vue'
import LoadingData from '@/components/commons/LoadingData.vue'
import UserHeader from '@/components/cards/UserHeader.vue'

export default {
  name: 'Home',
  data() {
    return {
      isLoading: true,
      quiz: [],
    }
  },
  components: {
    AvaliableQuiz,
    LoadingData,
    UserHeader,
  },

  methods: {
    async getActivesQuestionáries() {
      try {
        this.quiz = await this.$store.dispatch(
          'getQuestionariesAvaliables',
          this.user,
        )
        this.isLoading = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  mounted() {
    this.getActivesQuestionáries()
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
  },
}
</script>

<style scoped>
.home-container {
  height: 100vh;
  width: 100vw;
  background-image: url('~@/assets/img/backgrounds/background_roxo.png');
  background-size: cover;
}
</style>
