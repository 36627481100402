<template>
  <div
    class="d-flex"
    style="
      border-bottom-left-radius: 1px;
      border-bottom-right-radius: 1px;
      background-color: #321c9a;
    "
  >
    <div class="list-group list-group-borderless py-2 w-100">
      <div class="list-group-item fw-semibold text-white text-center">
        <h3 class="mb-0">BEM-VINDO</h3>
      </div>
      <div class="list-group-item hstack gap-3">
        <a href="#" class="status-indicator-container" v-if="userPhoto">
          <img
            :src="`${apiPath}${user.cpf}.jpg`"
            class="w-40px h-40px rounded-pill"
            alt=""
          />
          <span class="status-indicator bg-success"></span>
        </a>
        <div class="flex-fill text-white">
          <div class="fw-semibold">{{ user.name }}</div>
          <span class="text-muteds">{{ user.filialName }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserHeader',
  data() {
    return {
      apiPath: `${process.env.VUE_APP_BASE_URL_API}files/user_photo/`,
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    userPhoto() {
      return this.$store.getters.userPhoto
    },
  },
}
</script>
