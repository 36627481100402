<template>
  <div class="d-flex justify-content-center flex-column">
    <img src="@/assets/img/loading/loading_estrategia.gif" class="ms-wd" />
    <p class="text-primary text-center">CARREGANDO DADOS...</p>
  </div>
</template>

<script>
export default {
  name: 'LoadingData',
}
</script>
