<template>
  <div id="profile-container">
    <ProfileHeader title="PERFIL - CONQUISTAS" />
    <div class="text-center px-4">
      <Ranking v-if="ranking" :ranking="ranking" />
      <button
        type="button"
        @click="logout"
        style="background-color: #0063f6; color: #ffffff"
        class="btn btn-lg rounded-pill mt-3 md-wd"
        :disabled="disableButton"
      >
        Sair
      </button>
    </div>
  </div>
</template>
<script>
import PATHS from '@/router/routesMap'
import Ranking from '@/components/cards/Ranking.vue'
import ProfileHeader from '@/components/cards/ProfileHeader.vue'

export default {
  name: 'Profile',
  data() {
    return {
      isLoading: false,
      ranking: null,
      apiPath: `${process.env.VUE_APP_BASE_URL_API}files/user_photo/`,
    }
  },
  components: {
    Ranking,
    ProfileHeader,
  },
  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        localStorage.clear()
        this.$router.push(PATHS.BASE_URL)
      })
    },
    async getRanking() {
      try {
        this.ranking = await this.$store.dispatch('getRanking', {
          user: this.user,
        })
        this.isLoading = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
  },
  mounted() {
    this.getRanking()
  },
}
</script>

<style scoped>
#profile-container {
  height: 100vh;
  width: 100vw;
  background-image: url('~@/assets/img/backgrounds/background_roxo.png');
  background-size: cover;
}
</style>
