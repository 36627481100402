const dateNow = () => {
  const date = new Date()

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }
  return date.toLocaleDateString('pt-BR', options)
}
const compare2Dates = (date1, date2) => {
  const d1 = new Date(date1)
  const d2 = new Date(date2)
  return d1.getTime() - d2.getTime() > 0
    ? ' <span class="badge bg-danger">Vencido</span>'
    : ''
}

const dateNowUs = () => {
  const date = new Date()

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }
  return date.toLocaleDateString('fr-CA', options)
}
const dateNowUs30ago = () => {
  const today = new Date()
  const date = new Date(new Date().setDate(today.getDate() - 30))

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }
  return date.toLocaleDateString('fr-CA', options)
}

const dateTimeSqlToDateTimeBR = (value) => {
  const date = new Date(value)
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }
  return date.toLocaleDateString('pt-BR', options)
}

const dateTimeSqlToDateBR = (value) => {
  const date = new Date(`${value} 00:00:00`)
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }
  return date.toLocaleDateString('pt-BR', options)
}

const dateTimeSqlToDateWithT = (value) => {
  const dateWithOutT = value.replace('T', ' ')
  const date = new Date(dateWithOutT)
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }
  return date.toLocaleDateString('pt-BR', options)
}

const dateTimeRmToDateBR = (value) => {
  const date = new Date(`${value}`)
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }
  const dateFinal = date.toLocaleDateString('pt-BR', options)
  return dateFinal === 'Invalid Date' ? '' : dateFinal
}

const transformDateInObject = (value) => {
  return value.map((dateString) => {
    const dateObj = new Date(`${dateString} 00:00:00`)
    return [
      dateObj.getDate(),
      dateObj.getMonth() + 1,
      dateObj.getFullYear(),
    ].join('/')
  })
}

export {
  dateNow,
  dateNowUs,
  compare2Dates,
  dateNowUs30ago,
  dateTimeSqlToDateTimeBR,
  dateTimeSqlToDateBR,
  transformDateInObject,
  dateTimeRmToDateBR,
  dateTimeSqlToDateWithT,
}
